<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Relatório de usuários</h1>
        <!--end::Title-->
      </div>
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <div class="text-center py-5">
          <form @submit.prevent="">
            <div class="d-flex col-lg-8 mx-md-auto justify-content-center mb-5 flex-wrap">
              <DateTimeInput
                v-model.trim="form.start_date"
                format="dd/MM/yyyy"
                type="date"
                theme="basic"
                class="mx-2"
                placeholder="Início"
              />
              <DateTimeInput
                v-model.trim="form.end_date"
                format="dd/MM/yyyy"
                type="date"
                theme="basic"
                class="mx-2"
                placeholder="Término"
              />
              <small class="mt-2">Se não especificar o período, o relatório incluirá todos os usuários desde o início </small>
            </div>
            <div class="col-lg-6 mx-md-auto d-flex justify-content-center">
              <!--begin::Input group-->
              <div class="mb-5 d-flex text-start align-items-center">
                <!--begin::Label-->
                <label class="form-label fw-semibold mb-0 me-2">Verba:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <select class="form-select" aria-label="Select example" v-model="form.orders">
                    <option></option>
                    <option value="with_available_order">Com verba disponível</option>
                    <option value="without_available_order">Sem verba disponível</option>
                  </select>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
            </div>
            <div class="col-lg-6 mx-md-auto d-flex justify-content-center">
              <!--begin::Input group-->
              <div class="mb-5 d-flex text-start align-items-center">
                <!--begin::Label-->
                <label class="form-label fw-semibold mb-0 me-2">Campanha:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <select class="form-select" aria-label="Select example" v-model="form.campaigns">
                    <option></option>
                    <option value="with_active_campaign">Com campanha ativa</option>
                    <option value="without_active_campaign">Sem campanha ativa</option>
                  </select>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
            </div>
            <div class="col-lg-6 mx-md-auto d-flex justify-content-center">
              <!--begin::Input group-->
              <div class="mb-5 d-flex text-start align-items-center">
                <!--begin::Label-->
                <label class="form-label fw-semibold mb-0 me-2">Histórico:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <select class="form-select" aria-label="Select example" v-model="form.historic">
                    <option></option>
                    <option value="with_campaigns">Já tiveram campanhas</option>
                    <option value="without_campaigns">Não tiveram campanhas</option>
                    <option value="with_orders">Já incluiram verba</option>
                    <option value="without_orders">Nunca incluiram verba</option>
                    <option value="more_than_one_advertiser">Tenha mais de um anunciante</option>
                  </select>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
            </div>
            <div class="mt-5">
              <Button
                class="btn-primary"
                :loading="loader"
                @click="submit"
              >
                Extrair relatório
              </Button>
            </div>
          </form>
        </div>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import ReportsService from '@/modules/reports/services/reports-service'

import Button from '@/components/common/Button/Button'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: {
    Button,
    DateTimeInput,
    MainLayout
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
  data () {
    return {
      form: {
        start_date: '',
        end_date: '',
        orders: '',
        campaigns: '',
        historic: ''
      },
      loader: false
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        await ReportsService.requestUsersReport(this.form)
        const notification = {
          notification: {
            type: 'success',
            content: 'Relatório solicitado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
